@import './utils/mixins';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainWrapper {
 
  min-height: calc(100vh - 40px);
  // height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

  &_image {
    z-index: -1;
    margin: auto;
    
    position: fixed;
    background-image: url('../public/assets/accueil07.jpeg');
    backdrop-filter: brightness(0.8);
    background-size: cover;
    background-attachment: fixed;
    

    &_home {
      width: auto;
      height: auto;
      animation: goHome 2s ease forwards;
    }
    
    &_about {
      width: auto;
      height: auto;
      animation: goAbout 2s ease forwards;
    }

    &_skills {
      width: auto;
      height: auto;
      animation: goSkills 2s ease forwards;
      @media only screen and (max-width:$mobileMaxWidth){
          animation: none;
          opacity: 0;
      }
    }

    &_services {
      width: auto;
      height: auto;
      animation: goServices 2s ease forwards;
      @media only screen and (max-width:$mobileMaxWidth){
        animation: none;
        opacity: 0;
      }
    }

    &_work {
      width: auto;
      height: auto;
      animation: goWork 2s ease forwards;
    }

  }
}

.fade {
  opacity: 1;
  transition: opacity 500ms ease-in;
}