@import '../../utils/mixins';

.work {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    animation: apparition 1.5s linear;

    &_backArrow {
        position: absolute;
        right: 260px;
        top: -41px;
        display: flex;
        justify-content: flex-start;
        width: 115px;
        &:hover {
            filter: brightness(3);
        }
        
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 70px;
            padding-top: 0px;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            position: sticky;
            width: 70px;
            margin: 0 0 15px 30px;
            padding-top: 30px;
        };
    }

    &_image {
        animation: apparition 1s ease forwards;
        width: 100%;
        height: 800px;
        position: relative;
        overflow: hidden;
        background-image: var(--image-url), $bwGradient;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        margin-top: 0px;
        
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            height: 400px;
            display: block;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            height: 0;
            display: none;
        };
    }

    &_title {
        @include expressiveFont;
        font-size: 120px;
        @include colorGrade;
        display: flex;
        justify-content: center;
        margin: 100px auto;
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 70px;
            margin: 50px auto;
            text-align: center;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 40px;
            margin: 50px auto;
            text-align: center;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &_datasWrapper {
        width: 920px;
        margin: auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 100%;
            margin: 0;
            padding: 0 30px;
            box-sizing: border-box;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
            margin: 0;
            padding: 0 30px;
            box-sizing: border-box;
        }
        
    }
    &_description {
        display: flex;
        justify-content: center;
        margin: 0 auto 100px auto;
        text-align: justify;
        width: 100%;
        @include mainFont;
        font-size: 14px;
        color: $colorWhiteLabel;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            text-align: center;
            margin: 0 auto 50px auto;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 12px;
            text-align: center;
            margin: 0 auto 30px auto;
        };
    }

    &_infos {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: auto;
        margin: 0 auto 100px auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            column-gap: 50px;
            margin: 100px auto;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            flex-direction: column;
            margin: 0 auto;
        }

        &_technos {
            width: 350px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 100%;
                   
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
                margin: 30px auto;
            }

            &_title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @include mainFont;
                font-size: 20px;
                color: $colorWhiteLabel;
                margin-bottom: 15px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 15px;
                };
                

            }
            &_text {
                @include mainFont;
                font-size: 14px;
                color: $colorWhiteLabel;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 12px;
                    text-align: center;
                };
                
            }

        }

        &_links {

            display: flex;
            flex-direction: row;
            column-gap: 30px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                column-gap: 15px;
                margin: auto;
            }
            

            &_github, &_websiteUrl {
                width: 46px;
                
                padding: 3px;
                box-sizing: border-box;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 30px;
                }

                &:hover {
                    border: solid 1px $color0;
                    border-radius: 50%;
                }
            }
        }

        &_challenges {
            width: 350px;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 100%;
                
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
                margin: 30px auto;
            };
            display: flex;
            flex-direction: column;

            &_title {
                @include mainFont;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 20px;
                color: $colorWhiteLabel;
                margin-bottom: 15px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 15px;
                };
            }
            &_text {
                @include mainFont;
                font-size: 14px;
                color: $colorWhiteLabel;
                text-align: justify;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 12px;
                    text-align: center;
                };
            }
        }
    }
}