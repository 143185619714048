@import '../../utils/mixins';

@keyframes changeTextColor {
    0% { color: $gradient;
        opacity: 1; }
    30% { color: white }
    100% { opacity: 0; }
  }

@keyframes changeBorderColor {
    0% { opacity: 1;
        border-color: $color2 }
    30% { border-color: white }
    100% { opacity: 0; }
  }

.alertBox {
        width: 100%;
        aspect-ratio: 2;
        border: 1px solid;
        display: flex;
        align-items: center; /* Centrer verticalement */
        justify-content: center; /* Centrer horizontalement */
        animation: changeBorderColor 2s linear infinite;

        &--displayOn {
            display: flex;
        }

        &--displayOff {
            display: none;
        }

        &_container  {
            color: white;
            &_text {
                @include h4TitleFont;
            }
            animation: changeTextColor 2s linear infinite;
        }
}
