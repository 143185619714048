@import '../../utils/mixins';

.services {
    animation: apparition 1s linear;
    @include pageWrapper;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-bottom: 150px;
    margin-left: 30%;

    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        width: auto;
        margin: 0 0 0 30%;
        padding: 0 30px;
    }
    @media only screen and (max-width:$mobileMaxWidth){
        width: 100%;
        margin: 0;
        padding: 0 30px;
        text-align: center;
    }
    &_image {
        display: flex;
        margin: 100px auto;
        width: 93px;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin: 80px auto;
            width: 50px;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            margin: 80px auto;
            width: 50px;
        }
    };

    &_container {
        width: 100%;
        display: flex;
        flex-direction: column;

        &_list {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
 
            @media only screen and (max-width:$mobileMaxWidth){
                margin-bottom: 10px;
                align-items: flex-start;
                
            }

            &_item {
                margin-bottom: 15px;
                font-size: 48px;
                width: auto;
                @media only screen and (min-width:992px){
                    &:hover {
                        @include hoverStyle;
                    }
                }
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 30px;  
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 20px;
                    justify-content: center;
                    margin-bottom: 5px;
                    text-align: left;  
                }

                &--inactive {
                    @include expressiveFont;             
                    color:$colorDarkLabel;
                    letter-spacing: 5px;
                    text-transform: uppercase;
                }
                &--active {
                    @include expressiveFont;
                    @include colorGrade;
                    letter-spacing: 5px;
                    text-transform: uppercase;
                }
            }
        }

        &_description {
            
            width: 50%;
            @include mainFont;
            font-size: 14px;
            color: $colorWhiteLabel;
            font-weight: 200;
            animation: apparition 1s ease forwards;

            &--displayOn {
                animation: openCollapse 1s ease forwards;
                @media only screen and (max-width:$mobileMaxWidth){
                    animation: openMobileCollapse 1s ease forwards;
                }
   
            }
            &--displayOff {
                animation: closeCollapse 1s ease forwards;
                @media only screen and (max-width:$mobileMaxWidth){
                    animation: closeMobileCollapse 1s ease forwards;
                }
            }
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 80%;
                font-size: 12px;
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
                text-align: left;   
            }
        }
    }
}