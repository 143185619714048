@import '../../utils/mixins';

.error404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_404 {
        @include mainFont;
        @include colorGrade;
        font-size: 300px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 200px;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 100px;
        }
    }
    &_text {
        @include mainFont;
        color: white;
        font-size: 20px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 17px;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 13px;
        }
    }
}