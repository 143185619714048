@import '../../utils/mixins';

.works {
    @include pageWrapper;
    animation: apparition 1s linear;

    &_container {
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        row-gap: 30px;
        padding: 50px 30px;
        box-sizing: border-box;

        &_item {
            display: flex;
            flex-direction: column;

            &_title {
                display: flex;
                text-align: center;
                justify-content: center;
                @include expressiveFont;
                font-size: 100px;
                @include colorGrade;
                // @include relHoverStyle;
                &:hover {
                    @include hoverStyle
                };
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 90px;
                };
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 75%;
                    margin: auto;
                    font-size: 30px;
                };
            }
            &_subtitle {
                
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    flex-direction: column
                };
                
                @media only screen and (max-width:$mobileMaxWidth){
                    flex-direction: column;
                    
                }
    
                &_year {
                    @include mainFont;
                    font-size: 20px;
                    color: $colorWhiteLabel;
                    font-weight: 700;
                    text-transform: uppercase;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 15px;
                        
                    };
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 13px;
                        text-align: center;
                        
                    }
                }
                &_task {
                    @include mainFont;
                    font-size: 20px;
                    color: $colorWhiteLabel;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: 5px;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 15px;
                        
                    };
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 13px;
                        text-align: center;
                    }
                }
            }
        }
    }
}