/*-------------------------------
------------ FONTS --------------
-------------------------------*/

@font-face {
    font-family: 'expressiveFont';
    src: url('../assets/heading_now/HeadingNowTrial-27Extrabold.ttf') format('truetype');
}

@font-face {
    font-family: 'mainFont';
    src: url('../assets/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

/*--------------------------------
------------ MIXINS --------------
--------------------------------*/

@mixin mainFont {
    font-family: 'mainFont';
}

@mixin expressiveFont {
    font-family: 'expressiveFont';
}

@mixin colorGrade {
    background: $gradient;
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin backgroundColorGrade {
    background: $gradient;
}

@mixin button {
    width: auto;
    padding: 5px 10px;
    font-size: 16px;
    color: white;
    font-family: 'mainFont';
    cursor: pointer;
    background: none;
}

@mixin inputContainer {
    display: flex;
    flex-direction: column;
    padding: 3% 0;
}

@mixin pageWrapper {
    
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0 0;
}

@mixin h4TitleFont {
    font-size:22px;
    font-family: 'mainFont'; 
    text-transform : uppercase;  
    @media only screen and (max-width:$mobileMaxWidth){
        font-size:18px;
    }
}

@mixin h5TitleFont {
    font-size:20px;
    color: white;
    font-family: 'mainFont';
    text-transform : uppercase;   
}


@mixin errorText {
    color: pink;
    font-style: italic;
    font-family: 'mainFont';
    font-size:14px;
    padding:0;
    margin: 0;
    text-align : justify;
    margin-bottom: 20px;
}

@mixin okText {
    color: $color2;
    font-style: italic;
    font-family: 'mainFont';
    font-size:18px;
    padding:0;
    margin: 0;
    text-align : justify;
    margin: 0 auto 20px auto;
}

@mixin hoverStyle {
    animation: goHover 0.5s ease forwards;
    cursor: pointer;
    box-sizing: border-box;
}

/*-----------------------------------
------------ KEYFRAMES --------------
-----------------------------------*/

@keyframes apparition {
    0% { opacity: 0;
        display: none; }  
    100% { opacity: 1;
        display: flex; }
  }


@keyframes disparition {
    0% { opacity: 1;
        display: flex; }
    50% { opacity: 0;
        display: none; }
    100% { opacity: 0;
        display: none; }
}

@keyframes foldLeftFooter {
    0% { column-gap: 50%; }
    100% { column-gap: 6%; }
}

@keyframes unfoldLeftFooter {
    0% { column-gap: 6%; }
    100% { column-gap: 50%; }
}


@keyframes unfoldRightFooterGradientBar {
    0% { width: 100%;
        opacity: 1;
    }
    50% { width: 0%;
        opacity: 0;
        }
    100% { width: 0%;
        opacity: 0;
        }
}

@keyframes foldRightFooterGradientBar {
    0% { width: 0%;
    opacity: 0;
    }
        
    100% { width: 100%;
    opacity: 1;
    }
}

@keyframes mobileUnfoldRightFooterGradientBar {
    0% { width: 100%;
        height: 33%;
     }
    100% { width: 20%;
        height: 10%; }
}

@keyframes mobileFoldRightFooterGradientBar {
    0% { width: 20%;
        height: 10%; }
    100% { width: 100%;
        height: 33%; }
}

@keyframes openCollapse {
    0% {
        height: 0;
        display: none;
        opacity: 0;
    }
    50% {
        height: 75px;
        display: flex;
        opacity: 0;
    }
    100% {
        height: 75px;
        display: flex;
        opacity: 1;
    }
}

@keyframes closeCollapse {
    0% {
        height: 75px;
        display: flex;
        opacity: 0;
    }
    
    100% {
        height: 0;
        display: none;
        opacity: 0;
    }
}

@keyframes openMobileCollapse {
    0% {
        height: 0;
        display: none;
        opacity: 0;
    }
    50% {
        height: 120px;
        display: flex;
        opacity: 0;
    }
    100% {
        height: 120px;
        display: flex;
        opacity: 1;
    }
}

@keyframes closeMobileCollapse {
    0% {
        height: 100px;
        display: flex;
        opacity: 0;
    }
    
    100% {
        height: 0;
        display: none;
        opacity: 0;
    }
}

@keyframes goAbout {

    0% {
        clip-path: currentPath();
        opacity: 0;
    }

    10%{
        clip-path: circle(100% at center);
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    100% {
        clip-path: circle(30% at center);
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

@keyframes goHome {
    0%{
        clip-path: currentPath();
        opacity:0;
    }

    10%{
        clip-path: inset(0 0 0 0);
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    100% {
        clip-path: inset(0 0 0 0);
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

@keyframes goSkills {
    0%{
        clip-path: currentPath();
        opacity: 0;
    }

    10% {
        clip-path: inset(0 0 0 0);
        width: 100%;
        height: 100%;
        opacity: 0;
    }
   
    100% {
        clip-path: inset(0 0 0 0);
        width: 40%;
        height: 100%;
        opacity: 1;
    
    }
}

@keyframes goServices {
    0%{
        clip-path: currentPath();
        opacity: 0;
    }
    10% {
        clip-path: inset(0 0 0 0);
        width: 100%;
        height: 100%;
        opacity: 0;
    }
   
    100% {
        clip-path: inset(0 0 0 0);
        width: 25%;
        height: 100%;
        opacity: 1;
    }
}

@keyframes goWork {
    0%{
        clip-path: currentPath();
        opacity: 0;
    }
    10% {
        clip-path: inset(0 0 0 0);
        width: 0%;
        height: 0%;
        opacity: 0;
    }
   
    100% {
        clip-path: inset(0 0 0 0);
        width: 0%;
        height: 0%;
        opacity: 1;
    }
}

@keyframes goHover {
    0%{
        filter: brightness(1);
        padding-left: 0px;
        box-sizing: border-box;
        column-gap: 17px;
    }
    50% {
        filter: brightness(3);
        padding-left: 10px;
        box-sizing: border-box;
        column-gap: 7px;
    }
    100%{
        filter: brightness(1);
        padding-left: 0px;
        box-sizing: border-box;
        column-gap: 17px;
    }
}

/*---------------------------------
------------ VALEURS --------------
---------------------------------*/

$mobileMaxWidth: 767px;
$padMaxWidth: 991px;
$padMinWidth: 768px;

$color0: #f2caff;
$color1: #F0FF93;
$color2: #A7FF92;
$color3: #49EBC5;
$color4: #49D3FF;

$colorWhiteLabel: rgb(255, 255, 255);
$colorDarkLabel:#929292;
$colorTransparentLabel: rgba(255, 255, 255, 0.1);

$modalBackground: #202020;

$gradient:linear-gradient(90deg, $color0 0%, $color1 40%, $color2 60%, $color3 70%, $color4 100% );
$bwGradient:linear-gradient(90deg, $colorDarkLabel 0%, $colorWhiteLabel 100%);