@import '../../utils/mixins';

.footer {
    margin: 50px 0 30px 0;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 0px 0 30px 0; 
    }
    @media only screen and (max-width:$mobileMaxWidth){      
        margin: 0px 0 30px 0;
    }

    &_bottom {
        width: auto;
        height: 110px;
        @media only screen and (max-width:$mobileMaxWidth){ 
            height: auto;
        }

        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        justify-content: flex-end;
        @media only screen and (max-width:$mobileMaxWidth){
            flex-direction: column;
            row-gap: 50px;
        }

    
        &--fold {
            animation: foldLeftFooter 1s ease forwards;
        }
        &--unfold {
            animation: unfoldLeftFooter 1s ease forwards;
        }
    
        &_leftSide {
            width: 218px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 10px;
            
            @media only screen and (max-width:$mobileMaxWidth){
                margin: auto;
            }
    
            &_socialNav {
    
                height: auto;
                width: auto;

                
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 100%;
                    margin-top: 50px;
                }
            
                &_links {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
            
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;       
                    }
                    
                    &_logo {
                        height: 30px;
                        width: auto;

                        @media only screen and (max-width:$mobileMaxWidth){
                            height: 30px;
                        }
                        &:hover {
                            box-sizing: border-box;
                            padding: 2px;
                            border: 1px solid;
                            border-color: $color1 ;
                            border-radius: 50%;
                        }   
                    }
                }   
            }
    
            &_contact {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                
            
                &_planetIcon {
                    width: 50px;
                    height: auto;
                    align-self: flex-end;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        width: 50px;
                    };     
                    @media only screen and (max-width:$mobileMaxWidth){
                        display: none;
                    }
                }

                &_link {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    column-gap: 30px;
                    // @include relHoverStyle;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        padding-left: 15px;
                        box-sizing: content-box;
                    };
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        margin: auto;
                    }

                    &:hover {
                        @include hoverStyle;
                    }
                    
                    &_text {
                        width: 60px;
                        text-align: center;
                        @include mainFont;
                        @include colorGrade;
                        font-size: 10px;
                        font-weight: 300;
                        overflow-wrap: break-word;
                        letter-spacing: 6px;
                        align-self: flex-end;
                        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                            width: 60px;
                        };
                        
                    }
        
                    &_messageButton {
                        display: flex;
                        flex-direction: column;
                        width: 41px;
                        align-self: center;
                    }  
                }
            } 
        }
    
        &_rightSide {
            
            height: 100%;
            width: 700px;
            display: flex;
            justify-content: space-between;
            row-gap: 10px;
            flex-direction: column;
            box-sizing: border-box;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
            }

    
            &_title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @include mainFont;
                font-size: 20px;
                font-weight: 500;
                color: $colorWhiteLabel;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 15px;     
                };
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 15px;
                    width: 260px;
                    margin: auto;
                }
                &--fold {
                    animation: apparition 1s ease forwards;
                }
                &--unfold {
                    animation: disparition 1s ease forwards;
                }
                
            }
            &_description {
                color: $colorWhiteLabel;
                @include mainFont;
                text-align: justify;
                font-size: 12px;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 12px;
                };
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 10px;
                    text-align: center;
                    margin: auto;
                }
                &--fold {
                    animation: apparition 1s ease forwards;
                }
                &--unfold {
                    animation: disparition 1s ease forwards;
                }
            }
    
            &_gradientBar {
    
                background-image: $gradient;
                height: 20px;
    
                &--fold { 
                    animation: foldRightFooterGradientBar 1s ease forwards;
                    @media only screen and (max-width:$mobileMaxWidth){
                        animation: mobileFoldRightFooterGradientBar 1s ease forwards;
                    }
                }
                &--unfold {
                    animation: unfoldRightFooterGradientBar 1s ease forwards;
                    @media only screen and (max-width:$mobileMaxWidth){
                        animation: mobileUnfoldRightFooterGradientBar 1s ease forwards;
                    }
                }
            }
        } 
    }
}