@import '../../utils/mixins';

.header {

    margin: 20px auto;
    padding: 0 30px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 5%;

    &_rightSide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        column-gap: 10px;

        &_titles {
            display: flex;
            flex-direction: row;
            column-gap: 1%;
            width: 620px;
            justify-content: flex-start;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            };
            
            @media only screen and (max-width:$mobileMaxWidth){
                flex-direction: column;
                width: 260px;
            };
    
            &:hover {
                cursor: pointer;
                filter: brightness(0.5);
            };
            
            &_h1 {
                width: auto;
                height: auto;
                @include mainFont;
                text-transform : uppercase;
                color: $colorWhiteLabel;
                font-size: 24px;
                font-weight: 400;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
    
                };
                
                @media only screen and (max-width:$mobileMaxWidth){ 
                    font-size: 17px;
                };
            }
    
            &_h2 {
                width: auto;
                height: auto;
                @include mainFont;
                text-transform : uppercase;
                color: $colorWhiteLabel;
                font-size: 24px;
                font-weight: 200;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
    
                };
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 18px;
                };
            }
        }
        
        &_navLanguage {
            display: flex;
            flex-direction: row;
            column-gap: 5px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
    
            };
            @media only screen and (max-width:$mobileMaxWidth){
                
            }
    
            &_item {
                width: auto;
                height: auto;
                @include mainFont;
                text-transform : uppercase;
                color: $colorDarkLabel;
                font-size: 17px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
    
                };
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 16px;
                }
    
                &--active {
                    font-weight: 400;
                }
                &--notActive {
                    font-weight: 200;
                }
                &:hover {
                    cursor: pointer;
                }
    
            }
        }
    }

    

    &_navSystem {
        display: flex;
        flex-direction: column;
        height: auto;
        position: fixed;
        top: 30px;
        margin-top: 0px;
        right: 30px;
        z-index: 1;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        
        };
        
        @media only screen and (max-width:$mobileMaxWidth){
            position: absolute;
            margin-top: 60px;
            left: 30px;
            
        }

        &_gradientBars {
            z-index: 1;
            flex-direction: column;
            justify-content: space-between;
            align-self: end;
            height: 20px;
            width: 30px;
            cursor: pointer;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                height: 15px;
            };
                
            @media only screen and (max-width:$mobileMaxWidth){
                height: 15px;
                align-self: baseline;      
            }
                

            &--displayOn {
                display: flex;
            }

            &--displayOff {
                display: none;
            }

            &_gradientBar {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                background-image: $gradient;
                height: 2px;
                width: 100%;
                animation: apparition 2s ease forwards;
            }
        }

        &_nav {
            padding: 20px 0;
            display: flex;
            z-index: 1;
            width: 200px;
            height: 100%;
            align-items: flex-end;
            background-color: rgba(0, 0, 0, 0.8);
    
            &--displayOff {
                animation: disparition 0.5s ease forwards;
            }
    
            &--displayOn {
                animation: apparition 0.5s ease forwards;

            }
            @media only screen and (max-width:$mobileMaxWidth){
                align-items: flex-start;   
            }
    
            flex-direction: column;
            justify-content: space-between;
            column-gap: 1%;
            width: 100%;
            margin: auto;
            

            &_link {
    
                &--displayOff {
                    display: none;
                }
    
                &--displayOn {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }
    
                @media only screen and (max-width:$mobileMaxWidth){
                    flex-direction: row;
                    justify-content: flex-start;
                }
    
                &_h2 {
                    @include mainFont;
                    font-size: 16px;
                    color: $colorWhiteLabel;
                    // transform: rotate(10deg);
                    &--active {
                        @include colorGrade;
                    }     
                }
            }
    
            a:hover {
                h2, p {
                    filter: brightness(0.5);
                }
            }
    
            &_logoutButton {
    
                &--displayOff {
                    display: none;
                }
                &--displayOn {
                    display: block;
                    background: none;
                    border: none;
                    @include mainFont;
                    padding: 0;
                    color: $colorWhiteLabel;
                    font-size: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}