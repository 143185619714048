@import '../../utils/mixins';

.editPage {
    @include pageWrapper;
    padding: 0 30px;

    &_alertMessage {
        &--displayOn {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            padding-top: 250px;
            color: white;
            box-sizing: border-box;
            @include h4TitleFont;
            min-height: 600px;
        }
        &--displayOff {
            display: none;
        }
    
    }
    
    &_editSection {
        animation: apparition 1s linear;
        height: auto;
        
        &--displayOn {
            display: block;
        }
    
        &--displayOff {
            display: none;
        }
    
        &_container {
            width: 100%;
            display: grid;
            margin: auto;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 30px;
            row-gap: 40px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                grid-template-columns: 1fr 1fr 1fr;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex;
                flex-direction: column;
                margin-top: 50px;
            }
    
            &_item {
    
                width: 100%;
    
                img {
    
                    width: 100%;
                    aspect-ratio: 2;
                    object-fit: cover;
    
                }
    
                h3 {
                    @include h4TitleFont;
                    @include colorGrade;
                    margin-bottom: 5px;
                }
    
                &_buttons {
    
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
    
                    &_modifButton {
                        @include button;
                        font-size: 13px;
                        border: none;
                        padding: 0 10px 0 0;
        
                        &:hover {
                            filter: brightness(0.5);
                        }
                    }
                    &_supprButton {
                        @include button;
                        font-size: 13px;
                        border: none;
                        padding: 0 10px 0 0;
        
                        &:hover {
                            filter: brightness(0.5);
                        }
                    }
                }     
            }
        }
    
        &_addButton {
            display: flex;
            margin: 150px auto 30px auto;
            padding: 10px 0;
            justify-content: center;
            color: white;
            width: 20%;
            border: none;
            @include button;
            &:hover {
                @include colorGrade;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
                margin: 50px auto 30px auto;
            }
        }
    }
}

