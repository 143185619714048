@import '../../utils/mixins';

.scrollArrow {
    @include mainFont;
    color: $colorWhiteLabel;
    font-size: 30px;
    position: fixed;
    bottom: 30px;
    right: 50%;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        font-size: 20px;
        
    };
    
    @media only screen and (max-width:$mobileMaxWidth){
        font-size: 15px;
        
    }

    &--displayOn {
        animation: apparition 1s ease forwards;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    &--displayOff {
        animation: disparition 1s ease forwards;
        display: none;
        
    }
}