@import '../../utils/mixins';

.aboutSection {
    animation: apparition 1s linear;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    position: relative;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        width: 80%;
    }
    @media only screen and (max-width:$mobileMaxWidth){
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        box-sizing: border-box;
        margin: 50px auto 20px auto;
        align-items: center;
    }

    &_infos {
        width: 712px;
        display: flex;
        margin: 20px auto;
        padding-top: 8%;
        flex-direction: column;
        justify-content: center;
        row-gap: 10px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 90%;
            justify-content: left;       
        }
        @media only screen and (max-width:$mobileMaxWidth){
            padding: 0px 15px;
            box-sizing: border-box;
            width: 100%;
        }

        &_title {
            @include expressiveFont;
            font-size: 110px;
            @include colorGrade;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 70px;
            };           
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 70px;
                text-align: center;
            }
        }
        &_secondTitle {
            @include mainFont;
            font-size: 30px;
            color: $colorWhiteLabel;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 20px;
                width: 80%;
            };            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 17px;
                text-align: center;
            }
        }
        &_description {
            @include mainFont;
            font-size: 14px;
            color: $colorWhiteLabel;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 13px;
                width: 80%;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 12px;
                text-align: center;
            }
        }
    }

    &_myselfImage {
            width: 525px;
            position: relative;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 200px;
                position: absolute;
                z-index: 1;
                right: 0;
                top: 10%;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex;
                justify-content: center;
                width: 200px;
                margin: 30px 0;
            }

        img {
            width: 100%;            
        }
        
    }
}