@import '../../utils/mixins';

.skills {
    animation: apparition 1s linear;
    @include pageWrapper;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-bottom: 150px;
    margin-left: 50%;

    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        width: auto;
        margin: 0 0 0 40%;
        padding: 0 30px;
    };
    
    @media only screen and (max-width:$mobileMaxWidth){
        width: 100%;
        margin: 0;
        padding: 0 30px;
        text-align: center;
    }

    &_image {
        display: flex;
        margin: 100px auto;
        width: 93px;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin: 80px auto;
            width: 50px;
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            margin: 80px auto;
            width: 50px;
        }
    }
    
    &_titleList {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        margin-bottom: 20px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
       
        };  
        @media only screen and (max-width:$mobileMaxWidth){
            margin-bottom: 20px;
        }
        

        &_item {
            margin-bottom: 15px;
            // @include relHoverStyle;
            font-size: 48px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 30px; 
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 30px;
                justify-content: center;
                
            }
            &:hover {
                @include hoverStyle; 
            }
            
            &--inactive {
                @include expressiveFont;
                
                color:$colorDarkLabel;
                letter-spacing: 5px;
                text-transform: uppercase;
                @media (min-width: 992px) {
                    &:hover {
                      @include hoverStyle;
                    }
                }
            }
            &--active {
                @include expressiveFont;
                
                @include colorGrade;
                letter-spacing: 5px;
                text-transform: uppercase;
                
            }
        }
    }

    &_activeSkillList {
        
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 30px 0;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                margin: 10px 0;
            };  
            @media only screen and (max-width:$mobileMaxWidth){
                margin: 10px 0;
            }

            h4 {
                @include mainFont;
                font-size: 18px;
                color: $colorWhiteLabel;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 20px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 15px;
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 15px;
                    text-align: left;
                }
                
            }

            p {
                @include mainFont;
                font-size: 14px;
                color: $colorWhiteLabel;
                font-weight: 200;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 12px;
                };  
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 12px;
                    text-align: left;
                }
            }
        }
    }
}