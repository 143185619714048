@import '../../utils/mixins';

.authModal {  
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 0, 0, 0.8);
    

    &--displayOff {
        display: none;
    };
    &--displayOn {
        display: flex;
        flex-direction: column;
    }

    &_form {
        display: flex;
        flex-direction: column;
        width: 630px;
        height: auto;
        padding: 3% 0;
        background-color: black;
        border: 2px solid;
        border-image: $gradient;
        border-image-slice: 1;
        padding: 40px 0;

        &_title {
            margin: 0 auto 4% auto;
            @include expressiveFont;
            @include colorGrade;
            font-size: 30px;
        }

        div {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin: auto;

            label {
                @include mainFont;
                margin-bottom: 5px;
            }

            input {
                border: none;
                @include mainFont;
                background-color: rgba($color: #ffffff, $alpha: 0.1);
                margin-bottom: 15px;
            }
        }

        button {
            @include button;
            border: none;
            margin: 5% auto 0 auto;
            &:hover {
                @include colorGrade;
            }
        }    
    }

    &_closeButton {
        @include button;
        border: none;
        margin-top: 20px;
        &:hover {
            @include colorGrade;
        }
    }

    &_errorText {
        @include errorText;
    }
}