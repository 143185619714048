@import '../../utils/mixins';

.addForm  {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0, 0, 0.8);
    &--displayOn{
        display: flex;
    }
    &--displayOff{
        display: none;
    }

    &_form {
        display: flex;
        flex-direction: column;
        width: 630px;
        height: auto;
        padding: 3% 0;
        background-color: $modalBackground;
        overflow: scroll;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 100%;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
        }
        
        &_sampleContainer {
                width: 35%;
                margin: auto;
                
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 80%;
                }

            &_sample {
                width: 100%;
                aspect-ratio: 2;
                object-fit: cover;
            }
        }

        &_name, &_description, &_linkUrl, &_githubLink, &_functs, &_technos, &_role, &_year {

            @include inputContainer;
            width: 60%;
            margin: auto;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
            }

            label {
                @include h5TitleFont;
                margin: 0 auto 5px auto;
            }

            p {
                @include mainFont;
                font-style: italic;
                padding: 3px 0;
                color: $color1;
            }

            input, textarea {
                width: 100%;
                max-width:100%;
                min-width:100%;
                margin: auto;
                border: none;
                color: white;
                @include mainFont;
                background: rgba(255, 255, 255, 0.1);
            }
        }

        &_imageFile {
            @include inputContainer;
            width: 60%;
            margin: auto;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
            }

            label {
                @include button;
                border: none;
                color: white;
                margin: 5% auto 0 auto;
                padding: 3%;
                display: flex;
                justify-content: center;

                @media only screen and (max-width:$mobileMaxWidth){
                    width: 80%;
                }
                &:hover {
                    @include colorGrade;
                }
            }
        }

        &_errorText {
            @include errorText;
        }

        &_buttons {
            display: flex;
            flex-direction: row;
            padding: 3% 0;
            width: 60%;
            margin: auto;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
            }
            
            &_validButton {
                @include button;
                border: none;
                margin: 5% auto 0 auto;
                padding: 2%;
                &:hover {
                    @include colorGrade
                }
            }

            &_cancelButton {
                @include button;
                border: none;
                margin: 5% auto 0 auto;
                &:hover {
                    @include colorGrade
                }
            }
        }
    }
}