@import '../../utils/mixins';

.confirmBox {
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0, 0, 0.8);

    &--displayOn {
        display: flex;
    }

    &--displayOff {
        display: none;
    }

    &_container  {
        display: flex;
        flex-direction: column;
        width: 630px;
        height: auto;
        padding: 3% 0;
        background-color: $modalBackground;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                
        };
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
        }

        &_question {
            @include h5TitleFont;
            padding-top: 20px;
            margin: 0 auto 5px auto;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
                text-align: center;
            }
        }
        &_buttons {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            column-gap: 20px;
            padding: 3% 0;
            width: 60%;
            margin: auto;
            @media only screen and (max-width:$mobileMaxWidth){
                width: 80%;
            }

            button {
                @include button;
                width: 50%;
                margin: 5% auto 0 auto;
                padding: 2%;
                border: none;
                &:hover {
                    @include colorGrade;
                    }
            }
        }
    }
} 
