@import '../../utils/mixins';


.homeSection {
    @include pageWrapper;
    max-width: 100%;

    &_nav {
        width: auto;
        animation: apparition 1s linear;
        display: flex;
        height: auto;
        justify-content: flex-start;
        flex-direction: column;
        text-align: left;
        box-sizing: border-box;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 265px;
            text-align: left;
        };
        
        @media only screen and (max-width:$mobileMaxWidth){
            width: 200px;
            text-align: left;
            margin: 20px 0 20px 30px;
        }

        &_link, &_logoutButton {
            // @include relHoverStyle;

            &:hover {
                @include hoverStyle;
            }
        
            h2 {    
                display: flex;
                flex-direction: column;
                align-self: center;
            }

            font-size: 115px;
            @include expressiveFont;
            @include colorGrade;
            line-height: 0.9;
            
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 90px;
            };
            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 60px;
            }
        }

        &_logoutButton {
            text-decoration: none;
            padding: 0;
            border: none;
            @include mainFont;
            @include colorGrade;
            font-size: 40px;
            margin-bottom: 15px;
            text-align: left;

            &--displayOn {
                display: block;
            }
            &--displayOff {
                display: none;
            }
        }
    }
    
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        margin: 0 auto;
        justify-content: flex-start;
    };
}